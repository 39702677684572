<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="150px"
        size="small"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="货品估价"
              prop="goodsAssessment"
            >
              <el-input
                placeholder="请输入估值金额"
                v-model.trim="formData.goodsAssessment"
                @input="numberCtrol(formData, 'goodsAssessment', 2, 20)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="货品寄存天数"
              prop="goodsConsignDays"
            >
              <el-input
                placeholder="请输入寄存天数"
                v-model.trim="formData.goodsConsignDays"
                @input="numberCtrol(formData, 'goodsConsignDays', 0, 10)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="可寄存价值"
              prop="consignPrice"
            >
             <div class="money">¥ {{ formData.consignPrice }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="寄存费"
              prop="consignFee"
            >
              <div class="money">¥ {{ formData.consignFee }}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
        >计算</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import utils, { numberCtrol } from '@/utils/utils';
import api from '@/api/ValuationAttrApi';

export default {
  name: 'ValuationCalc',
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '估价计算器',
      loading: false,
      formData: {
        goodsAssessment: 0,
        goodsConsignDays: 0,
        consignPrice: 0,
        consignFee: 0,
      },
      rules: {
        goodsAssessment: [{ required: true, message: '请输入', trigger: 'change' }],
        goodsConsignDays: [{ required: true, message: '请输入', trigger: 'change' }],
      },
    };
  },
  watch: {},
  methods: {
    numberCtrol,
    validMoney(form, prop) {
      this[form][prop] = utils.validMoney(this[form][prop]);
    },
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.loading = true;
          const {
            goodsAssessment,
            goodsConsignDays,
          } = this.formData;

          api.assessmentCalculator(
            {
              goodsAssessment,
              goodsConsignDays,
            },
          )
            .then(res => {
              this.formData.consignPrice = res.result.consignPrice;
              this.formData.consignFee = res.result.consignFee;
            })
            .catch(err => {
              console.log(err);
              this.$message.error(err.message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
 .money {
   color: red;
   font-size: 18px;
   font-weight: bold;
 }
</style>
