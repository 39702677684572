<template>
  <div class="container">
    <el-dialog
      title="修改密码"
      v-drag
      custom-class="dialogClass"
      :visible.sync="dialogVisible"
      width="500px"
      @closed="closeDialog('elform')"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="form"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item
          label="新密码"
          prop="newPassword"
        >
          <el-input
            placeholder="请输入新密码"
            v-model.trim="form.newPassword"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认新密码"
          prop="surePassword"
        >
          <el-input
            placeholder="请确认新密码"
            v-model.trim="form.surePassword"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="sure('elform')" size="small">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api/UserManageApi';

export default {
  name: 'ChangePassword',
  data() {
    return {
      dialogVisible: false,
      form: {
        oldPassword: '',
        newPassword: '',
        surePassword: '',
      },
      rules: {
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'change' },
          { validator: this.validatePass, trigger: 'change' },
        ],
        surePassword: [
          { required: true, message: '请再次输入新密码', trigger: 'change' },
          { validator: this.validatePass2, trigger: 'change' },
        ],
      },
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
  },
  methods: {
    sure(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          alert(111);
          api.modifyPassword({
              userId: this.userInfo.userId,
              password: this.$md5(this.form.newPassword),
              confirmPassword: this.$md5(this.form.surePassword),
            }).then(res => {
            console.log(res);
            this.dialogVisible = false;
            this.$message.success(res.message);
            localStorage.removeItem('token');
            localStorage.removeItem('loginUser');
            this.$store.commit('navTag/clearTag');
            this.$store.commit('navMenu/navFixedin');
            this.$router.push('/login');
          }).catch(err => {
            console.log(err);
            this.$message.error(err.message);
          });
        }
      });
    },
    closeDialog(name) {
      this.$refs[name].resetFields();
    },
    // 自定义校验规则
    validatePass(rule, value, callback) {
      const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if (!reg.test(value)) {
        callback(new Error('8-16个字符，至少1个大写字母，1个小写字母和1个数字'));
      } else if (this.form.surePassword !== '') {
        this.$refs.elform.validateField('surePassword');
      }
      callback();
    },
    validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入新密码不一致!'));
      } else {
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  /deep/.el-dialog__footer{
    text-align:center;
  }
</style>
