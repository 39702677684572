<template>
  <div class="cp-worktab" @click.right.prevent="rightClick">
    <el-tabs
      v-model="activeTab"
      type="card"
      @tab-remove="removeTab"
      @tab-click="clickTab"

    >
      <el-tab-pane
        v-for="t in worktabs"
        :key="t.name"
        :label="t.tabname"
        :name="t.name"
        :closable="t.name !== 'home'"
        :dataKey="t.dataKey"
      >
      </el-tab-pane>
    </el-tabs>
    <div class="rightMenu" v-if="rightMenuShow" :style="{left: rightMenuLeft + 'px',right: rightMenuTop + 'px'}">
      <div class="rightMenu-item" @click.stop="closeThis">
        关闭这个标签
      </div>
      <div class="rightMenu-item" @click.stop="closeOther">
        关闭其它标签
      </div>
      <div class="rightMenu-item" @click.stop="closeAll">
        关闭所有标签
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      activeTab: '',
      rightMenuShow: false,
      rightMenuLeft: '',
      rightMenuTop: '',
      thisTag: '',
      thisTagIndex: '',
      thisTagName: '',
    };
  },
  created() {
    // 进来不是主页时等list加载后再更新一次current
    setTimeout(() => {
      this.activeTab = this.$store.state.navTag.worktab.current.name;
    }, 500);
    // 点击空白处关闭右键菜单
    document.addEventListener('click', e => {
      if (e.path[0].getAttribute('class')) {
        if (this.rightMenuShow === true) {
          if (e.path[0].getAttribute('class').split(' ').indexOf('rightMenu-item') === -1) {
            this.rightMenuShow = false;
          }
        }
      }
    });
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.navTag.worktab.current': function (tab) {
      this.activeTab = tab.name;
    },
    worktabs(val) {
      localStorage.setItem('tag_list', JSON.stringify(val));
    },
  },
  computed: {
    ...mapState({
      fixed: state => state.navMenu.fixed,
      worktabs: state => state.navTag.worktab.list,
    }),
  },
  methods: {
    clickTab(tab) {
      this.$router.push(this.worktabs[1 * tab.index].path).catch(() => {});
      if (!this.fixed) {
        this.$store.commit('navMenu/navFixed');
      }
      this.$store.commit('navMenu/updateSelectKey', tab.$attrs.dataKey);
    },
    removeTab(name) {
      let index = '';
      for (let i = 0; i < this.worktabs.length; i += 1) {
        if (this.worktabs[i].name === name) {
          if (i > 0) {
            index = this.worktabs[i - 1].dataKey;
          } else if (i === 0) {
            index = '';
          } else {
            index = this.worktabs[i + 1].dataKey;
          }
          if (name === this.$store.state.navTag.worktab.current.name) {
            this.$store.commit('navMenu/updateSelectKey', index);
          }
          this.$store.dispatch('navTag/worktabRemove', name);
        }
      }
    },
    // 右键菜单
    rightClick(e) {
      if (e.path[0].getAttribute('class')) {
        if (e.path[0].id.substring(4) !== 'home') {
          if (e.path[0].getAttribute('class').split(' ').indexOf('el-tabs__item') !== -1) {
            this.rightMenuLeft = e.pageX;
            this.rightMenuTop = e.pageY;
            this.rightMenuShow = true;
            this.worktabs.forEach((element, i) => {
              if (e.path[0].id.substring(4) === element.name) {
                this.thisTag = e.path[0].id.substring(4);
                this.thisTagIndex = i;
              }
            });
          }
        }
      }
    },
    // 关闭自己
    closeThis() {
      this.rightMenuShow = false;
      if (this.thisTag === this.$store.state.navTag.worktab.current.name) {
        this.$store.commit('navMenu/updateSelectKey', this.thisTagIndex);
      }
      this.$store.dispatch('navTag/worktabRemove', this.thisTag);
    },
    // 关闭其它
    closeOther() {
      this.rightMenuShow = false;
      let datakey = '';
      let thisIndex = '';
      if (this.worktabs[0].name === 'home') {
        thisIndex = 1;
        if (this.worktabs.length > 2) {
          this.worktabs.splice(thisIndex, this.thisTagIndex - 1);
          console.log(this.thisTagIndex);
          for (let i = this.worktabs.length - 1; i > -1; i -= 1) {
            this.worktabs.splice(i + thisIndex + 1, 1);
          }
          datakey = this.worktabs[thisIndex].dataKey;
          console.log(this.worktabs);
        } else {
          return;
        }
      } else {
        thisIndex = 0;
        if (this.worktabs.length > 1) {
          this.worktabs.splice(thisIndex, this.thisTagIndex);
          console.log(this.thisTagIndex);
          for (let i = this.worktabs.length - 1; i > -1; i -= 1) {
            this.worktabs.splice(i + thisIndex + 1, 1);
          }
          datakey = this.worktabs[thisIndex].dataKey;
          console.log(this.worktabs);
        } else {
          return;
        }
      }
      console.log(thisIndex);
      this.$router.push(this.worktabs[thisIndex].path);
      this.$store.commit('navMenu/updateSelectKey', datakey);
    },
    // 关闭所有
    closeAll() {
      this.rightMenuShow = false;
      let datakey = '';
      if (this.worktabs[0].name === 'home') {
        this.worktabs.splice(1);
        datakey = this.worktabs[0].dataKey;
      } else {
        this.worktabs.splice(0);
        datakey = '';
      }
      this.$router.push('/');
      this.$store.commit('navMenu/updateSelectKey', datakey);
    },
  },
};
</script>

<style lang="scss" scoped>
.cp-worktab {
  height: 40px;
  border-bottom: 0px solid #ccc !important;
  user-select: none;
  /deep/.el-tabs__header {
    border-bottom: none;
  }
  /deep/ .el-tabs__content {
    padding: 0 !important;
  }
  /deep/ .el-tabs--top {
    height: 40px;
  }
  /deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border-top: none !important;
    border-radius: 0 !important;
  }
  /deep/ .el-tabs__nav-wrap.is-scrollable {
    padding: 0 40px;
  }
  /deep/ .el-tabs__nav-next,
  /deep/.el-tabs__nav-prev {
    line-height: 40px !important;
    width: 40px;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    text-align: center;
  }
  /deep/ .el-tabs__nav-next:hover,
  /deep/.el-tabs__nav-prev:hover {
    border-bottom: 1px solid #e4e7ed !important;
    background: #f2f2f2 !important;
  }
  /deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    // border-bottom-color: #e4e7ed;
    border-bottom: none;
  }
  /deep/.is-focus {
    box-shadow: none !important;
  }
  .rightMenu {
    background: #ffffff;
    width: 150px;
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #f4f4f4;
    position: fixed;
  }
  .rightMenu-item {
    border-bottom: 1px solid #f4f4f4;
    padding: 10px;
    cursor: pointer;
  }
  .rightMenu-item:hover {
    background: #f4f4f4;
  }
  .rightMenu-item:last-child {
    border: none;
  }
}
</style>
