<template>
  <div class="header">
    <el-row type="flex" class="header-row" justify="space-between">
      <el-col :span="12" class="hd">
        <div
          class="icon"
          @click="
            toggleCollapse();
            emitToB();
          "
        >
          <i
            class="iconfont"
            :class="[collapse ? 'el-icon-s-fold' : 'el-icon-s-unfold']"
          ></i>
        </div>
        <h3>{{ title }}</h3>
      </el-col>
      <el-col :span="12" class="ft">
        <span class="infoItem"
          >欢迎您！<b>{{ userName }}</b></span
        >
        <span
          class="infoItem out"
          @click="changePassword"
        >
          <i class="el-icon-edit-outline"></i>
          <em>修改密码</em>
        </span>

        <el-button
          type="text"
          icon="iconfont icon-jisuanqi"
          @click="$refs.ValuationCalc.dialogVisible = true"
        >
          估价计算器
        </el-button>

        <el-button
          type="text"
          icon="el-icon-view"
          @click="$refs['renewal-fee-calc'].open()"
        >
          仓管费计算器
        </el-button>

        <el-button
          type="text"
          icon="iconfont icon-tuichudenglu"
          @click="logout"
        >
          退出登录
        </el-button>
      </el-col>

      <!-- <z-drawer></z-drawer> -->
    </el-row>
    <valuation-calc ref="ValuationCalc"></valuation-calc>
    <renewal-fee-calc ref="renewal-fee-calc"></renewal-fee-calc>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import eventBus from '@/js/eventBus';
import ValuationCalc from '../../TwoRecycling/ValuationAttr/ValuationCalc.vue';
import RenewalFeeCalc from '../../TwoRecycling/RenewalFee/RenewalFeeCalc.vue';

export default {
  name: 'LayoutHeader',
  components: {
    ValuationCalc,
    RenewalFeeCalc,
  },
  props: {},
  data() {
    return {
      fold: 'false',
    };
  },
  computed: {
    ...mapState({
      collapse: state => state.navMenu.collapse,
      userName: state => state.login.userName,
    }),
    ...mapGetters({
      title: 'navMenu/title',
    }),
  },
  methods: {
    toggleCollapse() {
      this.$store.commit('navMenu/toggleCollapse', !this.collapse);
    },
    changePassword() {
      this.$emit('changePassword');
    },
    logout() {
      this.$msgbox({
        title: '消息',
        message: '确定要退出吗？',
        center: true,
        closeOnClickModal: true,
        closeOnPressEscape: true,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          localStorage.clear();
          this.$router.push('/login').catch(err => {
            console.log(err);
          });
          this.$store.commit('navTag/clearTag');
          this.$store.commit('navMenu/navFixedin');
          this.$store.commit('navMenu/updateSelectKey', '');
          this.$store.commit('permission/SET_ROUTERS', []);
          // window.location.reload();
        })
        .catch(() => {});
    },
    emitToB() {
      this.fold = !this.fold;
      eventBus.$emit('fold', this.fold);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/element/element-variables.scss';
.header {
  position: relative;
  padding: 5px 20px;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .header-row {
    height: 100%;
  }
  .hd {
    display: flex;
    align-items: center;
    .icon {
      cursor: pointer;
      &:hover .iconfont {
        color: $--color-primary;
      }
    }
    .iconfont {
      color: #666666;
      font-size: 30px;
      transition: all 0.3s;
    }
    h3 {
      margin: 0;
      margin-left: 15px;
      font-size: 18px;
      color: #333333;
      font-weight: normal;
    }
  }
  .ft {
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span.infoItem {
      margin-left: 15px;
      margin-right: 25px;
      white-space: nowrap;
    }
    .out {
      color: $--color-primary;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      i {
        font-size: 18px;
        display: inline-block;
        vertical-align: middle;
      }
      em {
        display: inline-block;
        vertical-align: middle;
        font-style: normal;
      }
      b {
        color: #000;
        margin-left: 5px;
        font-weight: normal;
      }
    }
  }
}
</style>
