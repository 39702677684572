<template>
  <el-container>
    <!-- 多菜单
        <nav-menu-more></nav-menu-more>
    -->
    <!-- 少菜单 -->
    <nav-menu></nav-menu>
    <el-container>
      <el-header class="layout-header" height="90px">
        <layout-header @changePassword="changePassword"></layout-header>
        <nav-tag></nav-tag>
      </el-header>
      <el-main class="main">
        <transition
          name="pageTab"
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          mode="out-in"
          :duration="{ enter: 100, leave: 100 }"
        >
          <keep-alive :exclude="closingPage">
            <router-view class="content"></router-view>
          </keep-alive>
        </transition>
      </el-main>
    </el-container>
    <!-- 修改密码 -->
    <change-password ref="ChangePassword"></change-password>
  </el-container>
</template>

<script>
import { mapState } from 'vuex';
// 多菜单
// import NavMenuMore from './components/NavMenuMore.vue';
// 少菜单
import NavMenu from './components/NavMenu.vue';
import LayoutHeader from './components/LayoutHeader.vue';
import NavTag from './components/navTag.vue';
import ChangePassword from './components/ChangePassword.vue';

export default {
  name: 'app',
  components: {
    NavMenu,
    LayoutHeader,
    NavTag,
    ChangePassword,
  },
  data() {
    return {
      pathname: '',
    };
  },
  computed: {
    ...mapState({
      list: state => state.navMenu.list,
      closingPage: state => state.navTag.closingPage,
    }),
  },
  created() {
    const { pathname, hash } = location;
    if (hash === '') {
      this.pathname = pathname;
    } else {
      this.pathname = hash.substring(1);
    }
    let temp;
    const list = [...this.list];
    for (let i = 0; i < list.length; i += 1) {
      const item = list[i];
      if (item.children && item.children.length) {
        const { children } = item;
        for (let j = 0; j < children.length; j += 1) {
          const ele = children[j];
          const { key, to } = ele;
          if (to === this.pathname) {
            temp = key;
            break;
          }
        }
        if (temp) {
          break;
        }
      } else {
        const { to, key } = item;
        if (to === this.pathname) {
          temp = key;
          break;
        }
      }
    }
    if (temp) {
      this.$store.commit('navMenu/updateSelectKey', temp);
    }
  },
  mounted() {
    const screenWidth = document.body.clientWidth;
    if (screenWidth <= 768) {
      this.$store.commit('navMenu/toggleCollapse', false);
    }
  },
  methods: {
    changePassword() {
      this.$refs.ChangePassword.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: calc(100vh - 90px);
  overflow: auto;
  background: #e1e2e3;
  padding-top: 0 !important;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.el-container.is-vertical {
  flex: 1;
}
/deep/ .layout-header {
  box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.1);
  border-bottom: none;
  position: relative;
  z-index: 9;
}
</style>
