<template>
  <div class="nav-menu" :class="{ 'nav-menu-open': collapse }">
    <div class="logo">
      <!-- <i
        class="iconfont"
        :class="[fixed ? 'icon-guding_xuanzhong' : 'icon-guding_moren']"
        @click="toggleFixed"
      ></i> -->
      <img
        v-if="collapse"
        class="navlogo"
        src="@/assets/img/navlogo.jpg"
        alt=""
      />
      <span v-else class="logoIcon">aiya</span>
    </div>
    <div class="scroll-side" :class="{ close: !collapse }">
      <div class="scroll-scroll" :class="{ close: !collapse }">
        <el-menu
          background-color="#282b33"
          text-color="#e4e4e4"
          active-text-color="#ffffff"
          :default-active="selectKey"
          @select="select"
          :router="true"
          :unique-opened="false"
          :collapse="!collapse"
          :collapse-transition="false"
        >
          <template v-for="(item, index) in list">
            <el-submenu
              v-if="item.children"
              :key="'menu' + index"
              :index="item.key"
            >
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.title }}</span>
              </template>

              <!-- <el-menu-item
                v-for="(childrenItem,childrenIndex) in item.children"
                :key="'childrenItem'+index+childrenIndex"
                :index="childrenItem.key"
                :route="childrenItem.to"
              >{{childrenItem.title}}</el-menu-item> -->

              <template v-for="(citem, cindex) in item.children">
                <el-menu-item-group
                  class="elmenugroup"
                  v-if="citem.children"
                  :key="'citem' + index + cindex"
                >
                  <template slot="title">{{ citem.title }}</template>
                  <el-menu-item
                    v-for="(ccitem, ccindex) in citem.children"
                    :key="'ccitem' + cindex + ccindex"
                    :index="ccitem.key"
                    :route="ccitem.to"
                    >{{ ccitem.title }}</el-menu-item
                  >
                </el-menu-item-group>
                <el-menu-item
                  v-else
                  :key="'citem' + index + cindex"
                  :index="citem.key"
                  :route="citem.to"
                  >{{ citem.title }}</el-menu-item
                >
              </template>
            </el-submenu>
            <el-menu-item
              v-else
              :key="'menu' + index"
              :index="item.key"
              :route="item.to"
            >
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavMenu',
  props: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapState({
      selectKey: state => state.navMenu.selectKey,
      list: state => state.navMenu.list,
      collapse: state => state.navMenu.collapse,
      fixed: state => state.navMenu.fixed,
    }),
  },
  methods: {
    toggleFixed() {
      this.$store.commit('navMenu/toggleFixed');
    },
    select(index) {
      if (!this.fixed) {
        this.$store.commit('navMenu/toggleCollapse');
      }
      this.$store.commit('navMenu/updateSelectKey', index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/element/element-variables.scss';
.nav-menu {
  width: 64px;
  height: calc(100vh);
  background: #393d49;
  user-select: none;
  transition: all 0.3s;
  opacity: 1;
  overflow: auto;
  &.nav-menu-open {
    width: 200px;
  }
  .logo {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #393d49;
    // height: 70px;
    .iconfont {
      position: absolute;
      top: 12px;
      right: 12px;
      margin-right: 0;
      line-height: 1em;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.55);
      cursor: pointer;
    }
    .navlogo {
      width: 100%;
    }
    .logoIcon {
      display: inline-block;
      height: 53px;
      line-height: 53px;
      color: $--color-primary;
    }
  }
  .scroll-side {
    width: 200px;
    overflow-x: hidden;
    &.close {
      width: 64px;
      .el-menu {
        width: 64px;
      }
    }
    .scroll-scroll {
      width: 220px;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 90px);
      &.close {
        width: 84px;
      }
    }
  }
  .el-menu {
    border: none;
    width: 200px;
  }
  .el-menu-item.is-active {
    background-color: $--color-primary !important;
  }
  .el-menu-item.is-active::before {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 4px;
    content: '';
    background-color: $--color-primary;
  }
  .iconfont {
    margin-right: 10px;
    font-size: 25px;
  }
  /deep/.el-submenu__title {
    height: 45px;
    line-height: 45px;
    background-color: #393d49 !important;
    &:hover {
      background-color: #515563 !important;
    }
  }
  /deep/.el-submenu.is-active .el-submenu__title {
    color: #ffffff !important;
    // background-color: #484848 !important;
    & .iconfont {
      color: #ffffff;
    }
  }
  .el-submenu .el-menu-item {
    padding-left: 56px !important;
    height: 40px;
    line-height: 40px;
  }
}
</style>
